import _ from 'lodash'
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import mixpanel, { EVENTS } from 'lib/mixpanel'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_ONBOARDING,
  VALIDATE_ONBOARDING_FIELD,
} from './actions'

import { postOnboardingService } from './index'

export function* submitOnboardingSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const availableEmotionWords = yield select((state) => state.emotionWords.items)

    const onboardingData = {
      emotion_words: _.map(payload.emotionWords.value, (wordId) => ({
        emotion_word_id: wordId,
        actions: [],
      })),
    }

    if (payload.jobTitle && payload.jobTitle.value) {
      onboardingData.job_title_id = payload.jobTitle.value
    }

    if (payload.personTypeId && payload.personTypeId.value) {
      onboardingData.person_type_id = payload.personTypeId.value
    }

    if (payload.grades && payload.grades.value) {
      onboardingData.grades = payload.grades.value
    }

    if (payload.subjects && payload.subjects.value) {
      onboardingData.subjects = payload.subjects.value
    }

    const emotionWords = _.map(payload.emotionWords.value, (wordId) =>
      _.find(availableEmotionWords, { id: wordId }),
    )

    yield put(
      postOnboardingService.actions.request({
        data: onboardingData,
      }),
    )
    mixpanel.track(EVENTS.COMPLETE_ONBOARDING)
    _.forEach(emotionWords, (word) => {
      mixpanel.track(EVENTS.HOW_I_WANT_TO_FEEL_FROM_ONBOARDING, {
        emotion_word_id: word.id,
        emotion_word_name: word.name,
      })
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_ONBOARDING_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_ONBOARDING, submitOnboardingSaga),
  ])
}
