import React from 'react'
import _ from 'lodash'
import Router from 'next/router'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import mixpanel, { EVENTS } from 'lib/mixpanel'
import SuccessfulCompletionModal from 'page-modules/mood-meter/plot-journey/successful-completion-modal'
import lang from 'lang'
import buildService from 'common/utils/build-service'
import { urls } from 'common/routes'
import { changeModal } from 'services/actions'
import { validateField, validateFields } from 'common/validator'

import {
  addGroupFormFieldError,
  addGroupFormFieldsErrors,
  clearGroupFormFieldError,
  LOAD_MOOD_METER_GROUP_PAGE,
  resetMoodMeterGroupFormData,
  START_MOOD_METER_GROUP_PLOT,
  SUBMIT_GROUP_MOOD_METER_PLOT,
  SUBMIT_PERSONAL_MOOD_METER_PLOT,
  VALIDATE_MOOD_METER_GROUP_FORM_FIELD,
} from './actions'
import { SHARED_IN_OPTIONS } from './group-form-reducer'
import { getMoodMeterGroupByShortCodeService } from './mood-meter-service'

export const getMoodsService = buildService({
  type: 'GET',
  constantsPrefix: 'MOODS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ id, dates }) => {
      if (dates) {
        const moodsUrl = `/moods?mood_meter_group_id=eq.${id}&select=*,emotion_words(*)`
        return `${moodsUrl}&created_at=gte.${dates.from}&created_at=lte.${dates.to}`
      }
      return `/moods?mood_meter_group_id=eq.${id}&select=*,emotion_words(*)`
    },
  },
})

export const postMoodService = buildService({
  type: 'POST',
  constantsPrefix: 'MOODS',
  http: {
    headers: {
      Prefer: 'return=minimal',
    },
    createApiUrlSuffix: () => '/moods',
  },
})

export const deleteMoodService = buildService({
  type: 'DELETE',
  constantsPrefix: 'MOODS',
  http: {
    createApiUrlSuffix: ({ id }) => `/moods?id=eq.${id}`,
    *successHandler(mood) {
      yield put(changeModal({ isOpen: false }))
      yield put(getMoodsService.actions.removeItem({ id: mood.id }))
    },
  },
})

export const patchMoodMeterGroupsService = buildService({
  type: 'PATCH',
  constantsPrefix: 'MOOD_METER_GROUPS',
  http: {
    createApiUrlSuffix: ({ id }) => {
      return `/mood_meter_groups?id=eq.${id}`
    },
  },
})

export const getMyGroupsService = buildService({
  type: 'GET',
  constantsPrefix: 'MY_GROUPS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/my_mood_meter_groups`,
  },
})

export const getGroupsSharedWithMeService = buildService({
  type: 'GET',
  constantsPrefix: 'GROUPS_SHARED_WITH_ME',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/mm_groups_shared_with_me`,
  },
})

export const getMoodMeterGroupsService = buildService({
  type: 'GET',
  constantsPrefix: 'MOOD_METER_GROUPS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ id, short_url, owner_id, shared_in }) => {
      let queryParamsString = '?'
      if (shared_in) {
        queryParamsString += `shared_in=eq.${shared_in}`
        // When the shared in is school directly do
        // teh request. Don't add owner_id, as the
        // shared group MM plot can have a
        // different owner.
        if (shared_in !== SHARED_IN_OPTIONS.personal) {
          return `/mood_meter_groups${queryParamsString}`
        }
      }
      if (id) return `/mood_meter_groups${queryParamsString}&id=eq.${id}`
      if (short_url) return `/mood_meter_groups${queryParamsString}&short_url=eq.${short_url}`
      if (owner_id) return `/mood_meter_groups${queryParamsString}&owner_id=eq.${owner_id}`
      return `/mood_meter_groups`
    },
  },
})

export const postMoodMeterGroupService = buildService({
  type: 'POST',
  constantsPrefix: 'MOOD_METER_GROUPS',
  http: {
    createApiUrlSuffix: () => '/mood_meter_groups',
    *successHandler(data) {
      yield put(changeModal({ isOpen: false }))
      yield put(resetMoodMeterGroupFormData())

      mixpanel.track(EVENTS.START_MOOD_METER_GROUP, {
        mood_meter_group_id: data.id,
        mood_meter_group_name: data.name,
        mood_meter_short_url: data.short_url,
      })
      Router.push(urls.moodMeterGroupDetails(data.short_url))
    },
    *failureHandler(error) {
      yield put(
        addGroupFormFieldError({
          field: 'base',
          error: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
    },
  },
})

export const deleteMoodMeterGroupsService = buildService({
  type: 'DELETE',
  constantsPrefix: 'MOOD_METER_GROUPS',
  http: {
    createApiUrlSuffix: ({ id }) => `/mood_meter_groups?id=eq.${id}`,
    *successHandler(mood) {
      yield put(getMoodMeterGroupsService.actions.removeItem({ id: mood.id }))
    },
  },
})

export const closeMoodMeterGroupService = buildService({
  type: 'POST',
  constantsPrefix: 'CLOSE_MOOD_METER_GROUP',
  http: {
    createApiUrlSuffix: () => `/rpc/mood_meter_group_close`,
  },
})

function* submitMoodMeterPlot(formData, groupId) {
  const data = {
    chosen_word_id: formData.chosenEmotionWord,
    reason: formData.reason,
    pleasantness: formData.pleasantness,
    energy: formData.energy,
  }
  if (groupId) {
    data.mood_meter_group_id = groupId
    delete data.reason
  }
  yield call(postMoodService.requestSaga, {
    payload: {
      data,
    },
  })
}

export function* submitPersonalMoodMeterPlot({ payload }) {
  const formData = yield select((state) => state.moodMeter.forms.plot.data)
  yield call(submitMoodMeterPlot, formData, payload.groupId)
  // Track the personal mood meter plot event
  mixpanel.track(EVENTS.PLOT_PERSONAL_MOOD_METER, {
    is_personal: true,
    pleasantness: formData.pleasantness,
    energy: formData.energy,
    emotion_word_id: formData.chosenEmotionWord,
    reason: formData.reason,
  })
  yield put(
    changeModal({
      isOpen: true,
      hideCloseButton: true,
      shouldCloseOnOverlayClick: false,
      aria: { labelledby: 'successful-completion-header' },
      onClose: () => {
        window.location = urls.moodMeter()
      },
      className: 'successful-completion',
      content: <SuccessfulCompletionModal />,
    }),
  )
}

export function* submitGroupMoodMeterPlot({ payload }) {
  const formData = yield select((state) => state.moodMeter.forms.plot.data)
  yield call(submitMoodMeterPlot, formData, payload.groupId)
  const group = yield select((state) => state.moodMeter.group.item)
  const { short_url } = group
  mixpanel.track(EVENTS.PLOT_MOOD_METER, {
    mood_meter_group_id: group.id,
    mood_meter_group_name: group.name,
    mood_meter_short_url: short_url,
  })
  Router.push(urls.moodMeterPlotInGroupSuccess(short_url))
}

export function* validateMoodMeterGroupFormFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addGroupFormFieldError({ field, error }))
  } else {
    yield put(clearGroupFormFieldError({ field }))
  }
}

export function* startGroupMoodMeter({ payload }) {
  const errors = validateFields(payload)
  if (_.isEmpty(errors)) {
    const data = {
      name: payload.groupName.value,
      audience_type_id: payload.audienceType.value,
      duration: payload.duration.value,
      shared_in: payload.sharedIn.value,
    }

    if (_.isDate(payload.startTime.value)) {
      data.start_time = payload.startTime.value.toISOString()
    }

    yield put(
      postMoodMeterGroupService.actions.request({
        data,
      }),
    )
  } else {
    yield put(addGroupFormFieldsErrors(errors))
  }
}

function* loadMoodMeterGroupPageSaga({ payload: { req, shortCode } }) {
  yield call(getMoodMeterGroupByShortCodeService.requestSaga, {
    payload: {
      req,
      data: { short_code: shortCode },
    },
  })
  yield put(getMoodsService.actions.requestSuccess([]))
}

export default function* Saga() {
  yield all([
    takeLatest(SUBMIT_GROUP_MOOD_METER_PLOT, submitGroupMoodMeterPlot),
    takeLatest(SUBMIT_PERSONAL_MOOD_METER_PLOT, submitPersonalMoodMeterPlot),
    takeLatest(VALIDATE_MOOD_METER_GROUP_FORM_FIELD, validateMoodMeterGroupFormFieldSaga),
    takeLatest(START_MOOD_METER_GROUP_PLOT, startGroupMoodMeter),
    takeLatest(LOAD_MOOD_METER_GROUP_PAGE, loadMoodMeterGroupPageSaga),
    fork(postMoodService.saga),
    fork(getMoodsService.saga),
    fork(deleteMoodService.saga),
    fork(getMoodMeterGroupsService.saga),
    fork(getMoodMeterGroupByShortCodeService.saga),
    fork(postMoodMeterGroupService.saga),
    fork(patchMoodMeterGroupsService.saga),
    fork(deleteMoodMeterGroupsService.saga),
    fork(getMyGroupsService.saga),
    fork(getGroupsSharedWithMeService.saga),
    fork(closeMoodMeterGroupService.saga),
  ])
}
