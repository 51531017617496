import _ from 'lodash'
import getConfig from 'next/config'
import mixpanel from 'mixpanel-browser'

import { District, School, User } from 'common/types'

const { publicRuntimeConfig } = getConfig()

const mixpanelToken = publicRuntimeConfig.MIXPANEL_TOKEN

export enum EVENTS {
  VISIT_RESOURCE,
  VISIT_COURSE,
  VISIT_COURSE_MODULE,
  COMPLETE_COURSE_MODULE,
  COMPLETE_COURSE,
  COMPLETE_NUDGE,
  PUBLISH_NUDGE_SET,
  UNPUBLISH_NUDGE_SET,
  VISIT_CURRICULUM,
  LOGIN,
  LOGOUT,
  START_MOOD_METER_GROUP,
  PLOT_MOOD_METER,
  PLOT_PERSONAL_MOOD_METER,
  START_ONBOARDING,
  COMPLETE_ONBOARDING,
  FAVORITE_RESOURCE,
  UNFAVORITE_RESOURCE,
  UPLOAD_SHOWCASE,
  LIKE_SHOWCASE_POST,
  CREATE_SCHOOL_CHARTER,
  ADD_EMOTION_WORDS_TO_CHARTER,
  ADD_EMOTION_WORDS_TO_SCHOOL_CHARTER,
  HOW_I_WANT_TO_FEEL_FROM_ONBOARDING,
  HOW_I_WANT_TO_FEEL_FROM_PROFILE,
}

export const mixpanelEnabled =
  publicRuntimeConfig.SERVER_ENV === 'production' || publicRuntimeConfig.SERVER_ENV === 'staging'

export default mixpanelEnabled
  ? {
      init: () => {
        mixpanel.init(mixpanelToken)
      },
      boot: (user: User, school?: School, district?: District) => {
        if (!mixpanelToken) return

        mixpanel.identify(user.id)
        const data = {
          user_id: user.id,
          created_at: user.created_at,
          onboarded_at: user.onboarded_at,
          status: user.status,
          name: user.name,
          email: user.email,
          role: user.role_name,
          label: user.person_type_name,
          self_reged: user.self_reged,
          grades: _.join(_.map(user.grades, 'name'), ', '),
          subjects: _.join(_.map(user.subjects, 'name'), ', '),
          emotion_words: _.join(_.map(user.emotion_words, 'name'), ', '),
          job_title: user.job_title,
        }
        mixpanel.people.set(data)
        if (user.school_id) {
          mixpanel.set_group('school_id', user.school_id)
          mixpanel.get_group('school_id', user.school_id).set({
            name: user.school,
            created_in_ruler_online: school?.created_at,
          })
          if (user.school) {
            mixpanel.set_group('school_name', user.school)
            mixpanel.get_group('school_name', user.school).set({
              id: user.school_id,
              name: user.school,
              created_in_ruler_online: school?.created_at,
            })
          }
        }

        if (user.district_id) {
          mixpanel.set_group('district_id', user.district_id)
          mixpanel.get_group('district_id', user.district_id).set({
            name: user.district,
            created_in_ruler_online: district?.created_at,
          })
          if (user.district) {
            mixpanel.set_group('district_name', user.district)
            mixpanel.get_group('district_name', user.district).set({
              id: user.district_id,
              name: user.district,
              created_in_ruler_online: district?.created_at,
            })
          }
        }
      },
      reset: () => {
        mixpanel.reset()
      },
      track: (event: EVENTS, data: Record<string, string | number>) => {
        mixpanel.track(EVENTS[event], data)
      },
    }
  : { init: _.noop, boot: _.noop, reset: _.noop, track: _.noop }
